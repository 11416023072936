const config = {
  "broker": "1",
  "name": "English",
  "registrationBundle": "english",
  "defaultCountryName": "South Africa",
  "defaultCountryCode": "ZA",
  "fxgeoip_code": "ZA",
  languageCode: 'en',
  languageName:'English',
  spokenLanguageCode:'en',
  direction:'ltr',
  footerDisclaimer:'766'
};
module.exports = config;